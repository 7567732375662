import Vue from "vue";
import vueSmoothScroll from "vue-smoothscroll";

Vue.use(vueSmoothScroll);

new Vue({
  el: "#app",
  data: {
    modal_active: false,
    searchBox_active: false,
    drawer_active: false,
    admin_menu: false,
    category_menu: false,
    calendar_num: 0,
    modal_img_active: false,
    modal_img_src: "",
  },
  created: function () {},
  methods: {
    clickSmoothScroll(target) {
      this.$SmoothScroll(document.querySelector("#" + target), 600, null, null, "y");
    },
    clickScrollTop() {
      this.$SmoothScroll(0, 600, null, null, "y");
    },
    modalImage(event) {
      const imgSrc = event.target.src;

      if (imgSrc) {
        this.modal_img_src = imgSrc;
        this.modal_img_active = true;
      }
    },
  },
});

/* eslint-disable no-undef */
$(function () {
  $(".inview").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("viewed");
    }
  });
  $(".fade").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("fadeIn");
    }
  });
});
